// Import dependencies
import React from "react"

// Import components
import { BoxComponent, ParagraphText } from "../../styled-components/UILibrary"

/*
    Main button component
*/
const NavLink = ({ title, icon, onClick, id, carousel }) => (
  <BoxComponent
    radius={20}
    padding="25px"
    style={{ marginBottom: 25, cursor: "pointer" }}
    onClick={() => onClick(id)}
  >
    {icon}
    <ParagraphText center separator margin="margin-top: 10px">
      {title}
    </ParagraphText>
  </BoxComponent>
)

export default NavLink
