// Import dependencies
import React from "react"
import ReactResizeDetector from "react-resize-detector"
import Slider from "react-slick"

// Import components
import Layout from "../layouts/layout"
import SEO from "../components/seo/seo"
import { PageContainer } from "../styled-components/UILibrary"
import TitleSection from "../components/sections/titleSection"
import HeaderSeparator from "../components/separators/headerSeparator"
import NavLink from "../components/faqs/navLink"
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import SearchBar from "../components/search-bar/searchBar";

// Import styles
import "./faqs.css"
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

// Import data
import { navLinks, sectionContent } from "../data/faqs"
import DropDowns from "../components/faqs/dropDowns"
import SectionSeparator from "../components/separators/sectionSeparator";

/*
    FAQs page
*/
export default class FAQs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSection: "our-business",
      currentWidth: 0,
    }
    this.changeSection = this.changeSection.bind(this)
  }

  // Changes current FAQ section, called from NavLinks
  changeSection = id => {
    this.setState({ currentSection: id })
  }

  onResize = width => {
    this.setState({ currentWidth: width })
  }

  render() {
    const { location: { state } } = this.props
    // redirect from index page from bage
    const fromHero = Boolean(state && state.fromHero)

    return (
      <ReactResizeDetector handleWidth onResize={this.onResize}>
        <Layout>
          <CategoryDrawer/>
          <SEO
            title="FAQ"
            description="Learn about us, pricing, collections, and more."
          />

          <SearchBar/>
          <SectionSeparator/>

          {/* Page content */}
          <PageContainer>
            <TitleSection
              title="FAQs"
              paragraph={` `}
            />

            <div className="faqs__container">
              <div className="faqs__navigation">
                {this.state.currentWidth > 850 ? (
                  navLinks.map((item, i) => (
                    <NavLink
                      title={item.title}
                      icon={item.icon}
                      onClick={this.changeSection}
                      id={item.id}
                      key={i}
                    />
                  ))
                ) : (
                  <Slider
                    {...{
                      className: "center",
                      centerMode: true,
                      slidesToShow:
                        this.state.currentWidth > 600
                          ? 3
                          : this.state.currentWidth > 400
                          ? 2
                          : 1,
                      speed: 500,
                      dots: true,
                      initialSlide: 0,
                      centerPadding: 0,
                      infinite: true,
                      swipeToSlide: true,
                      rows: 1,
                    }}
                  >
                    {navLinks.map((item, i) => (
                      <NavLink
                        title={item.title}
                        icon={item.icon}
                        onClick={this.changeSection}
                        id={item.id}
                        key={i}
                      />
                    ))}
                  </Slider>
                )}
              </div>

              <div className="faq__content">
                <DropDowns defaultActiveKey={fromHero? 1: null} data={sectionContent[this.state.currentSection]}/>
              </div>
            </div>
          </PageContainer>
        </Layout>
      </ReactResizeDetector>
    )
  }
}
